import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import { getTextContent, fetchGeolocation } from 'helpers';
import { NavStepIcon } from './CustomIcons';
import ReactHtmlParser from 'react-html-parser';

class LocationElement extends React.Component {
  static propTypes = {
    element: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object,
    player: PropTypes.object
  };

  state = {
    geo: null,
    stepCount: 0,
    step: null
  };

  getContent() {
    const query = URI(document.location.toString()).query(true);
    const { element } = this.props;
    const { content, defaultText, feature, location } = element;
    const {
      country,
      community,
      province,
      zone,
      neightborhood,
      addresss,
      street,
      streetNumber,
      postalCode,
      busStation,
      trainStation,
      subwayStation
    } = this.props.location;
    let { geo } = this.state;

    if (!location && this.props.location) {
      geo = this.props.location;
    }
    let textContent = '';

    if (geo) {
      /**
       * wgXXX Overrides widget values using querystring parameters without altering conditional values.
       * wgDistrict, wgCity
       *
       */

      const district = query.wgDistrict || geo.district || '';
      const city = query.wgCity || geo.city || '';

      switch (feature) {
        case 'district':
          textContent = district;
          break;
        case 'city':
          textContent = city;
          break;
        case 'distanceUnits':
          textContent = this.playerDistanceUnits();
          break;
        case 'distanceDuration':
          textContent = this.playerDistanceDuration();
          break;
        case 'distanceDirection':
          textContent = this.playerDistanceDirection();
          break;
        case 'distanceTraffic':
          textContent = this.playerDistanceTraffic();
          break;
        case 'distanceMap':
          textContent = this.playerDistanceMap();
          break;
        default:
          textContent = '';
          break;
      }
    } else {
      textContent = content;
    }

    if (textContent === '') {
      if (defaultText) {
        textContent = defaultText;
      } else {
        switch (feature) {
          case 'country':
            textContent = country;
            break;
          case 'community':
            textContent = community;
            break;
          case 'province':
            textContent = province;
            break;
          case 'zone':
            textContent = zone;
            break;
          case 'neightborhood':
            textContent = neightborhood;
            break;
          case 'addresss':
            textContent = addresss;
            break;
          case 'street':
            textContent = street;
            break;
          case 'streetNumber':
            textContent = streetNumber;
            break;
          case 'postalCode':
            textContent = postalCode;
            break;
          case 'busStation':
            textContent = busStation;
            break;
          case 'trainStation':
            textContent = trainStation;
            break;
          case 'subwayStation':
            textContent = subwayStation;
            break;
          default:
            textContent = '';
            break;
        }
      }
    }

    return getTextContent({ ...element, content: textContent });
  }

  render() {
    const { style } = this.props.element;

    return (
      <div stylename="location" style={style}>
        {this.getContent()}
      </div>
    );
  }

  componentDidMount() {
    const { content, feature, distance } = this.props.element;

    if (!content) {
      this.fetchGeo();
    }

    this.interval = setInterval(this.fetchGeo.bind(this), 10 * 60 * 1000);

    if (feature === 'distanceDirection') {
      if (!this.state.step) {
        this.setState({ step: distance.steps[0] });
      }
      this.intervalDistanceDirection = setInterval(
        this.stepCounter.bind(this),
        2000
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { location, feature, distance } = this.props.element;

    if (location && location !== prevProps.element.location) {
      this.fetchGeo();
    }

    if (feature === 'distanceDirection') {
      if (this.state.stepCount === distance.steps.length) {
        this.setState({ stepCount: 0, step: distance.steps[0] });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalDistanceDirection);
  }

  fetchGeo() {
    const { player, element } = this.props;
    const { location, feature } = element;

    fetchGeolocation(location, feature, player)
      .then((json) => this.setState({ geo: json }))
      .catch((err) => this.setState({ error: err }));
  }

  stepCounter = () => {
    const { distance } = this.props.element;
    const { stepCount } = this.state;

    if (stepCount < distance.steps.length) {
      return this.setState((prevState) => ({
        stepCount: prevState.stepCount + 1,
        step: distance.steps[prevState.stepCount + 1]
      }));
    }
  };

  playerDistanceUnits() {
    const { distance } = this.props.element;
    if (distance.units !== 'imperial') {
      if (distance.meters >= 1000) {
        const km = distance.meters / 1000;
        return `${km.toFixed(1)} km`;
      }
      return `${distance.meters} m`;
    }
    const feets = distance.meters * 1.5;

    return feets;
  }

  playerDistanceDuration() {
    const { distance } = this.props.element;

    return distance.duration;
  }

  playerDistanceDirection() {
    const { distance, style } = this.props.element;
    const { stepCount, step } = this.state;
    const info = ReactHtmlParser(step, {
      decodeEntities: true,
      transform: (node) => {
        if (node.type === 'tag' && node.name === 'div') {
          return <div stylename="advice">{node.children[0].data}</div>;
        }
      }
    });
    const navigation = distance.steps.map((item, i) => (
      <NavStepIcon
        key={item}
        size={style.width}
        backgroundColor={stepCount === i ? style.color : "#FFFFFF"}
        borderColor={stepCount === i ? "#FFFFFF" : style.color}
      />
    ));

    return (
      <div stylename="steps">
        <div>{info}</div>
        <div>{navigation}</div>
      </div>
    );
  }

  playerDistanceTraffic() {
    const { intl, element } = this.props;
    const textContent = element.distance.estimate
      ? intl.formatMessage({ id: element.distance.estimate })
      : '';

    return textContent;
  }

  playerDistanceMap() {
    const { staticmap, name } = this.props.element;

    return <img src={staticmap.url} alt={name} />;
  }
}

export default LocationElement