import React from "react";
import { Route, Router } from "wouter";
import ExternalPlayer from "./ExternalPlayer";
import Player from "./Player";
import ErrorBoundary from "./ErrorBoundary";
import { IntlProvider } from "react-intl";
import { LOCALES } from "i18n/locales";
import { MESSAGES } from "i18n/messages";
function App() {
  const params = new URLSearchParams(document.location.search);
  let locale = params.get("lang");
  if (!Object.values(LOCALES).includes(locale)) {
    locale = LOCALES.DEFAULT;
  }

  return (
    <IntlProvider
      messages={MESSAGES[locale] || MESSAGES[LOCALES.ENGLISH]}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
    >
      <ErrorBoundary>
        <Router>
          <Route path="/playlist/:creativeId">
            {(params) => (
              <Player creativeId={params.creativeId} playlist={true} />
            )}
          </Route>
          <Route path="/external/creatives" component={ExternalPlayer} />
          <Route path="/player/:creativeId">
            {(params) => <Player creativeId={params.creativeId} />}
          </Route>
        </Router>
      </ErrorBoundary>
    </IntlProvider>
  );
}

export default App;
